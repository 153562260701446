import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191'),
	() => import('./nodes/192'),
	() => import('./nodes/193'),
	() => import('./nodes/194'),
	() => import('./nodes/195'),
	() => import('./nodes/196'),
	() => import('./nodes/197'),
	() => import('./nodes/198'),
	() => import('./nodes/199'),
	() => import('./nodes/200'),
	() => import('./nodes/201'),
	() => import('./nodes/202'),
	() => import('./nodes/203'),
	() => import('./nodes/204'),
	() => import('./nodes/205'),
	() => import('./nodes/206')
];

export const server_loads = [2,4,5,19,20,21,22,25,26,27,28,29,31,33,36,13,14,40,17,18,9];

export const dictionary = {
		"/(base)/(marketing)": [~62,[2,11],[3]],
		"/(base)/(app)/account": [41,[2,4,5],[3]],
		"/(base)/(app)/account/holds": [~42,[2,4,5],[3]],
		"/(base)/(app)/account/notifications": [~43,[2,4,5],[3]],
		"/(base)/(app)/account/payment": [~44,[2,4,5],[3]],
		"/(base)/(app)/account/profile": [~45,[2,4,5],[3]],
		"/(base)/admin": [~77,[2,19],[3]],
		"/(base)/admin/ai": [78,[2,19],[3]],
		"/(base)/admin/farms": [~79,[2,19],[3]],
		"/(base)/admin/farms/create": [~125,[2,19],[3]],
		"/(base)/admin/farms/[farmId]": [~80,[2,19,20],[3]],
		"/(base)/admin/farms/[farmId]/blackout-periods": [~81,[2,19,20],[3]],
		"/(base)/admin/farms/[farmId]/boxes": [~82,[2,19,20],[3]],
		"/(base)/admin/farms/[farmId]/boxes/create": [~84,[2,19],[3]],
		"/(base)/admin/farms/[farmId]/boxes/[boxId]/edit": [~83,[2,19],[3]],
		"/(base)/admin/farms/[farmId]/campaign-schedules": [~85,[2,19,20],[3]],
		"/(base)/admin/farms/[farmId]/campaign-schedules/[campaignScheduleId]": [~86,[2,19,20],[3]],
		"/(base)/admin/farms/[farmId]/coupons": [~87,[2,19,20],[3]],
		"/(base)/admin/farms/[farmId]/coupons/create": [~89,[2,19,20],[3]],
		"/(base)/admin/farms/[farmId]/coupons/[couponId]": [~88,[2,19,21],[3]],
		"/(base)/admin/farms/[farmId]/delivery": [~90,[2,19,20],[3]],
		"/(base)/admin/farms/[farmId]/delivery/[deliveryOptionId]": [~91,[2,19,20],[3]],
		"/(base)/admin/farms/[farmId]/intervals": [~92,[2,19,20],[3]],
		"/(base)/admin/farms/[farmId]/intervals/create": [~94,[2,19],[3]],
		"/(base)/admin/farms/[farmId]/intervals/[intervalId]/edit": [~93,[2,19],[3]],
		"/(base)/admin/farms/[farmId]/organization": [~95,[2,19,20],[3]],
		"/(base)/admin/farms/[farmId]/pickup": [~96,[2,19,20],[3]],
		"/(base)/admin/farms/[farmId]/pickup/create": [~98,[2,19,20],[3]],
		"/(base)/admin/farms/[farmId]/pickup/[pickupOptionId]": [~97,[2,19,20],[3]],
		"/(base)/admin/farms/[farmId]/plans": [~99,[2,19,20],[3]],
		"/(base)/admin/farms/[farmId]/plans/create": [~120,[2,19],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]": [100,[2,19,22],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/coupons": [~101,[2,19,22],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/coupons/add": [~102,[2,19],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/delivery-options": [~103,[2,19,22],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/delivery-options/add": [~105,[2,19,22],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/delivery-options/[deliveryOptionId]": [~104,[2,19,22],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/faqs": [~106,[2,19,22],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/faqs/add": [~109,[2,19],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/faqs/[faqId]/delete": [~107,[2,19],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/faqs/[faqId]/edit": [~108,[2,19],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/intervals": [~110,[2,19,22],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/intervals/add": [~111,[2,19],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/members": [~112,[2,19,22],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/pickup-options": [~113,[2,19,22],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/pickup-options/add": [~115,[2,19,22],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/pickup-options/[csaPlanPickupOptionId]": [~114,[2,19,22],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/settings": [~116,[2,19],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/share-purchase-options": [~117,[2,19,22],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/share-purchase-options/add": [~118,[2,19],[3]],
		"/(base)/admin/farms/[farmId]/plans/[planId]/waitlist": [~119,[2,19,22],[3]],
		"/(base)/admin/farms/[farmId]/share-purchase-options": [~121,[2,19,20],[3]],
		"/(base)/admin/farms/[farmId]/share-purchase-options/create": [~123,[2,19],[3]],
		"/(base)/admin/farms/[farmId]/share-purchase-options/[optionId]/edit": [~122,[2,19],[3]],
		"/(base)/admin/farms/[farmId]/waitlists": [~124,[2,19,20],[3]],
		"/(base)/admin/metrics": [~126,[2,19],[3]],
		"/(base)/admin/tools/algolia": [~127,[2,19],[3]],
		"/(base)/admin/tools/calendar": [128,[2,19],[3]],
		"/(base)/admin/tools/email": [129,[2,19],[3]],
		"/(base)/admin/ui": [130,[2,19],[3]],
		"/(base)/admin/ui/forms/standard": [~131,[2,19],[3]],
		"/(base)/admin/ui/layout/grid": [132,[2,19],[3]],
		"/(base)/admin/ui/layout/list": [133,[2,19],[3]],
		"/(base)/admin/ui/layout/sidebar-end": [134,[2,19,23],[3]],
		"/(base)/admin/ui/layout/sidebar-start": [135,[2,19,24],[3]],
		"/(base)/admin/users": [~136,[2,19],[3]],
		"/(base)/admin/users/create": [~141,[2,19],[3]],
		"/(base)/admin/users/[userId]": [~137,[2,19,25],[3]],
		"/(base)/admin/users/[userId]/holds": [~138,[2,19,25],[3]],
		"/(base)/admin/users/[userId]/notifications": [~139,[2,19,25],[3]],
		"/(base)/admin/users/[userId]/subscriptions": [~140,[2,19,25],[3]],
		"/(base)/(auth)/auth/redirect": [~55,[2,10],[3]],
		"/(base)/(auth)/auth/verify": [~56,[2,10],[3]],
		"/(base)/csa/[slug]": [~142,[2,26],[3]],
		"/(base)/csa/[slug]/add-ons": [~143,[2,26],[3]],
		"/(base)/csa/[slug]/confirm": [~144,[2,26],[3]],
		"/(base)/csa/[slug]/plans": [~145,[2,26],[3]],
		"/(base)/csa/[slug]/share": [~146,[2,26,27],[3]],
		"/(base)/csa/[slug]/share/delivery": [~147,[2,26,27],[3]],
		"/(base)/csa/[slug]/share/pickup": [~148,[2,26,27],[3]],
		"/(base)/csa/[slug]/share/pickup/waitlist/success": [~149,[2,26,27],[3]],
		"/(base)/csa/[slug]/share/sign-up": [~150,[2,26,27],[3]],
		"/(base)/csa/[slug]/sign-up": [~151,[2,26],[3]],
		"/(base)/dashboard": [~152,[2,28],[3]],
		"/(base)/dashboard/contacts": [~153,[2,28],[3]],
		"/(base)/dashboard/contacts/[contactId]": [154,[2,28,29],[3]],
		"/(base)/dashboard/contacts/[contactId]/conversations": [~160,[2,28,29],[3]],
		"/(base)/dashboard/contacts/[contactId]/(nav)/invoices": [~155,[2,28,29,30],[3]],
		"/(base)/dashboard/contacts/[contactId]/(nav)/orders": [~156,[2,28,29,30],[3]],
		"/(base)/dashboard/contacts/[contactId]/(nav)/payment": [~157,[2,28,29,30],[3]],
		"/(base)/dashboard/contacts/[contactId]/(nav)/profile": [158,[2,28,29,30],[3]],
		"/(base)/dashboard/contacts/[contactId]/(nav)/subscriptions": [~159,[2,28,29,30],[3]],
		"/(base)/dashboard/conversations": [161,[2,28,31],[3]],
		"/(base)/dashboard/conversations/[conversationId]": [162,[2,28,31],[3]],
		"/(base)/dashboard/csa/fulfillment/route/[routeId]": [~166,[2,28,34],[3]],
		"/(base)/dashboard/csa/fulfillment/[fulfillmentDate]": [~163,[2,28,33],[3]],
		"/(base)/dashboard/csa/fulfillment/[fulfillmentDate]/orders": [~164,[2,28,33],[3]],
		"/(base)/dashboard/csa/fulfillment/[fulfillmentDate]/routes": [~165,[2,28,33],[3]],
		"/(base)/dashboard/customers": [~167,[2,28,35],[3]],
		"/(base)/dashboard/customers/retail": [~169,[2,28,35],[3]],
		"/(base)/dashboard/customers/wholesale": [~170,[2,28,35],[3]],
		"/(base)/dashboard/customers/wholesale/[orgId]": [~171,[2,28],[3]],
		"/(base)/dashboard/customers/[userId]": [~168,[2,28],[3]],
		"/(base)/dashboard/marketing": [~172,[2,28],[3]],
		"/(base)/dashboard/marketing/campaign/[campaignId]": [~173,[2,36],[3]],
		"/(base)/dashboard/marketing/campaign/[campaignId]/blocks": [174,[2,36],[3]],
		"/(base)/dashboard/marketing/campaign/[campaignId]/blocks/[blockId]": [175,[2,36],[3]],
		"/(base)/dashboard/marketing/campaign/[campaignId]/box/[boxId]": [~176,[2,36],[3]],
		"/(base)/dashboard/marketing/campaign/[campaignId]/feature": [177,[2,36],[3]],
		"/(base)/dashboard/marketing/campaign/[campaignId]/preview": [~178,[2,36],[3]],
		"/(base)/dashboard/marketing/campaign/[campaignId]/recipe": [179,[2,36],[3]],
		"/(base)/dashboard/marketing/campaign/[campaignId]/update": [180,[2,36],[3]],
		"/(base)/dashboard/orders/csa/[orderId]": [~183,[2,28],[3]],
		"/(base)/dashboard/orders/csa/[fulfillmentDate]/[locationId]/[userId]": [~182,[2,28],[3]],
		"/(base)/dashboard/orders/[orderId]": [~181,[2,28,37],[3]],
		"/(base)/dashboard/products": [~184,[2,28],[3]],
		"/(base)/dashboard/products/create": [~186,[2,28],[3]],
		"/(base)/dashboard/products/[farmProductId]": [~185,[2,28],[3]],
		"/(base)/dashboard/reports": [~187,[2,28],[3]],
		"/(base)/dashboard/reports/past-due": [~188,[2,28],[3]],
		"/(base)/dashboard/reports/payouts": [~190,[2,28,39],[3]],
		"/(base)/dashboard/reports/payout/[payoutId]": [~189,[2,28,38],[3]],
		"/(base)/dashboard/reports/plans": [~191,[2,28],[3]],
		"/(base)/dashboard/reports/plans/[planId]": [~192,[2,28],[3]],
		"/(base)/dashboard/reports/sales": [~193,[2,28],[3]],
		"/(base)/dashboard/settings": [~194,[2,28],[3]],
		"/(base)/dashboard/settings/fulfillment": [~195,[2,28],[3]],
		"/(base)/dashboard/settings/integrations": [~196,[2,28],[3]],
		"/(base)/dashboard/settings/teammate/[orgUserId]": [~197,[2,28],[3]],
		"/email/[campaignId]": [~205],
		"/(base)/farmstand/[shopSlug]": [~198,[2],[3]],
		"/(base)/farmstand/[shopSlug]/product/[farmProductId]": [~199,[2],[3]],
		"/(base)/(public)/farm/[slug]": [65,[2,13,14],[3]],
		"/(base)/(public)/farm/[slug]/checkout": [~68,[2,13,14],[3]],
		"/(base)/(public)/farm/[slug]/onboarding": [~69,[2,13,14],[3]],
		"/(base)/(public)/farm/[slug]/orders": [~71,[2,13,14,16],[3]],
		"/(base)/(public)/farm/[slug]/orders/cancelled": [~72,[2,13,14,16],[3]],
		"/(base)/(public)/farm/[slug]/order/[orderId]": [~70,[2,13,14],[3]],
		"/(base)/(public)/farm/[slug]/product/[farmProductId]": [~73,[2,13,14],[3]],
		"/(base)/(public)/farm/[slug]/(auth)/sign-in": [~66,[2,15],[3]],
		"/(base)/(public)/farm/[slug]/(auth)/verify": [~67,[2,15],[3]],
		"/(base)/graphql": [~200,[2],[3]],
		"/(base)/(app)/invoices": [~46,[2,4,6],[3]],
		"/(base)/(app)/invoices/upcoming/[id]": [~47,[2,4,6],[3]],
		"/(base)/links/[linkId]": [~201,[2],[3]],
		"/(base)/maintenance": [202,[2],[3]],
		"/(base)/newsletter/[farmSlug]": [~203,[2,40],[3]],
		"/(base)/newsletter/[farmSlug]/success": [204,[2,40],[3]],
		"/(base)/(app)/orders": [~48,[2,4,7],[3]],
		"/(base)/(app)/orders/cancelled": [~50,[2,4,7],[3]],
		"/(base)/(app)/orders/csa": [~51,[2,4,7],[3]],
		"/(base)/(app)/orders/csa/[orderId]": [~53,[2,4,7],[3]],
		"/(base)/(app)/orders/csa/[fulfillmentDate]/[locationId]": [~52,[2,4],[3]],
		"/(base)/(app)/orders/[orderId]": [~49,[2,4,8],[3]],
		"/(base)/(privacy)/privacy": [~63,[2,12],[3]],
		"/sentry-example": [206],
		"/(base)/(public)/shop/[slug]": [74,[2,13,17],[3]],
		"/(base)/(public)/shop/[slug]/checkout": [~75,[2,13,17,18],[3]],
		"/(base)/(public)/shop/[slug]/product/[farmProductId]": [~76,[2,13,17],[3]],
		"/(base)/(auth)/sign-in": [~57,[2,10],[3]],
		"/(base)/(auth)/sign-out": [58,[2,10],[3]],
		"/(base)/(auth)/sign-up": [~59,[2,10],[3]],
		"/(base)/(auth)/sign-up/retail": [~60,[2,10],[3]],
		"/(base)/(auth)/sign-up/success": [61,[2,10],[3]],
		"/(base)/(app)/subscriptions": [~54,[2,4,9],[3]],
		"/(base)/(privacy)/tos": [~64,[2,12],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';